@tailwind base;
@tailwind components;
@tailwind utilities;

.form-box .form-label {
    font-size: 14px;
    color: #777777;
    margin-bottom: .125rem !important;
}

.form-box .form-control {
    font-size: 14px;
    color: #555555;
    padding: 6px .75rem;
    border-radius: 2px !important;
}

.form-box .form-select {
    font-size: 14px;
    color: #555555;
    padding: .125rem .75rem;
    border-radius: 2px !important;
    height: 36px;
}

.form-box .card .card-header {
    background-color: var(--primary-colorRGB);
    padding: 6px 1rem !important;
}

.groupDelet {
    background: #fd9090;
    width: 30px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: #fafafa;
    border-radius: 0px 2px 2px 0px;
}

.groupSelectBox {
    display: flex;
}

.groupSelectBox select {
    width: calc(100% - 30px);
}

.react-datepicker-popper {
    z-index: 9999999 !important;
}

div:where(.swal2-container) {
    z-index: 100000000000 !important;
}

.box-body {
    height: 100%;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}


.responsive-custom-table .form-control {
    border-radius: 2px !important;
    padding: 1px 4px;
    height: 30px !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.responsive-custom-table .page-link {
    padding: 4px !important;
    color: #555 !important;
    height: 30px !important;
    overflow: hidden;
    line-height: 20px;
    font-size: 14px;
    cursor: pointer;
}

.responsive-custom-table .page-link:hover {
    background-color: var(--primary-colorRGB);
}

.responsive-custom-table .jumpPage {
    outline: none !important;
    border: none !important;
    height: 20px !important;
}

.responsive-custom-table table td {
    padding: 6px !important;
    font-size: 14px !important;
}

.responsive-custom-table table tr th:first-child,
.responsive-custom-table table tr td:first-child {
    width: 50px !important;
    text-align: center !important;
}

.responsive-custom-table table tr th:last-child {
    width: 60px !important;
}

.responsive-custom-table table tr th:nth-last-child(2) {
    width: 90px !important;
}

.action-btn-box {
    display: flex;
}

.action-btn-box a {
    margin-right: 4px;
    width: 24px;
    height: 24px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
}

span.Search-input-table {
    display: flex;
    align-items: center;
}

.action-btn-box a.view {
    color: #00ad00;
    padding: 3px;
    border: 1px solid #bee5be;
}

.action-btn-box a.edit {
    color: #000095;
    padding: 3px;
    border: 1px solid #b4b4ff;
}

.action-btn-box a.delete {
    color: #ff9696;
    border: 1px solid #ffc7c7;
}

.action-btn-box a.restore {
    color: #005bd1;
    border: 1px solid #91c0ff;
}

.action-btn-box a.view:hover {
    background: #00ad00;
    color: #fff;
}

.action-btn-box a.edit:hover {
    background: #000095;
    color: #fff;
}

.action-btn-box a.delete:hover {
    background-color: #ff9696;
    color: #fff;
}

.action-btn-box a.restore:hover {
    background-color: #005bd1;
    color: #fff;
}

.archive-box span {
    font-size: 13px;
    color: #555555;
}

.archive-box .border-bottom {
    background-color: var(--secondary-colorRGB);
}

.declane-btn-primary {
    background-color: rgb(230, 173, 173);
    color: #555555;
    outline: none;
    border: 1px solid rgb(230, 173, 173);
    transition: all 0.3s ease-in-out;
    padding: 4px 12px;
}

.declane-btn-primary:hover {
    background-color: rgb(245, 95, 95);
    border: 1px solid rgb(245, 95, 95);
}